*{
  margin: 0;
  padding: 0;
  font-family: "Varela Round", serif;
  font-style: normal;
  
}

html {
  scroll-behavior: smooth;
}


