/* 
.title-main{
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    margin: 30px 0;
}
.courses-main{
    text-align: center;
    background-color: #f9f9f9;
    margin-top: 50px;
    background-color: #FFD700;
    width:70%;
    margin: 0 auto;
    border-radius: 20px;
}

.course-animation{
    height:400px;
}

.coming-soon{
    font-size: 3.5rem;
    margin: auto 0;
    width: 40%;
    font-weight: 700;
    color:#fff;
}
.upcoming-courses{
    display: flex;
    justify-content: space-around;
    justify-items: flex-start;
}
 */

 .title-main {
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    margin: 30px 0;
}

.courses-main {
    text-align: center;
    background-color: #FFD700;
    margin-top: 50px;
    width: 70%;
    margin: 0 auto;
    border-radius: 20px;
    padding: 20px;
}

.course-animation {
    height: 400px;
    max-width: 100%; /* Ensures animation scales properly */
}

.coming-soon {
    font-size: 3.5rem;
    margin: auto 0;
    width: 40%;
    font-weight: 700;
    color: #fff;
}

.upcoming-courses {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* Media Queries */

/* For tablets (max-width: 768px) */
@media (max-width: 768px) {
    .courses-main {
        width: 90%;
    }

    .upcoming-courses {
        flex-direction: column;
        gap: 20px;
    }

    .coming-soon {
        font-size: 2.5rem;
        width: 100%; /* Full width for better readability */
        text-align: center;
    }

    .course-animation {
        height: 300px; /* Adjust height for smaller screens */
    }
}

/* For mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .courses-main {
        width: 95%;
    }

    .coming-soon {
        font-size: 2rem;
    }

    .course-animation {
        height: 200px;
    }
}
