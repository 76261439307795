.about-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    gap: 20px;
    /* background: url('industry-background-final.png'); */
    background-color: #000;
    /* margin-bottom: 60px; */
    width:80%;
    margin: 60px auto;
    border-radius: 20px;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 350px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.flip-card-front {
    background-color: #FFF;
    color: #000;
}

.flip-card-back {
    background-color: #FFD700;
    color: white;
    transform: rotateY(180deg);
    font-size: 1.2rem;
    font-weight: 600;
    transition: 1s;
}
.flip-card-back :hover{
    color:#000;
    transition: 2s;
}

.card-title {
    margin-top: 10px;
    font-weight: bold;
    font-size: 2em;
}

/* Smooth transition effect */
.flip-card-inner, .flip-card-front, .flip-card-back {
    transition: all 0.4s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-main {
        flex-direction: column;
        align-items: center;
    }

    .flip-card {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }
}
