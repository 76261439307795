
.our-team-main {
  padding: 40px;
  background-color: #f5f5f5;
  text-align: center;
  margin-top: 50px;
  background: url(../pages/industry-background-final.png);
}
.our-team-title p{
  width:30%;
  margin : 20px auto;
  border-radius: 20px;
  background-color: #ffd700;
}
.our-team-title a {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.our-team-office {
  margin-top: 20px;
}


.office-ceo,
.the-head-dep {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.office {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.the-bald-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

/* Media Queries for Responsive Design */

@media (max-width: 550px) {
  /* Mobile view adjustments */

  .our-team-title p {
    font-size: 2rem;
    width:60%;
  }

  .office,
  .office-ceo,
  .the-head-dep,
  .the-bald-team {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Tablet view adjustments */

  .our-team-title p {
    font-size: 2.2rem;
  }

  .office,
  .office-ceo,
  .the-head-dep,
  .the-bald-team {
    flex-wrap: wrap;
    gap: 15px;
  }
}

@media (min-width: 1025px) {
  /* Desktop view adjustments */
  
  .our-team-title p {
    font-size: 3rem;
  }
  
  .office {
    flex-direction: row;
  }
}