.featured-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin: 50px 0;
  padding: 30px;
  background: linear-gradient(135deg, #f4e17a, #eacbed);
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.featured-title {
  font-size: 2.8rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.latest-blog-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.latest-blog-content {
  text-align: center;
}

.latest-blog-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.latest-blog-author {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.latest-blog-read {
  padding: 10px 20px;
  background-color: #ffd700;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.latest-blog-read:hover {
  background-color: #eacbed;
}

.other-blogs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.arrow {
  cursor: pointer;
  font-size: 2.5rem;
  color: #000;
  transition: color 0.3s ease;
}

.arrow:hover {
  color: #ffd700;
}

.blogs-list {
  display: flex;
  gap: 20px;
  width: 80%;
  padding: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.blogs-list::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.blog-card {
  flex: 0 0 30%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  height: 100px; /* Fixed height for alignment */
  overflow: hidden;
}

.blog-author {
  font-size: 1rem;
  color: #555;
  font-style: italic;
  margin-bottom: 15px;
  height: 20px; /* Fixed height for alignment */
}

.blog-read {
  padding: 8px 15px;
  background-color: #ffd700;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  height: 40px; /* Fixed height for alignment */
}

.blog-read:hover {
  background-color: #eacbed;
}

@media (max-width: 810px) {
  .blog-card {
    flex: 0 0 60%;
  }
}

@media (max-width: 480px) {
  .latest-blog-card {
    flex-direction: column;
  }
  .blogs-list {
    flex-wrap: nowrap;
  }
}
